import React from "react"
import Layout from "../../../templates/layout"
import SEO from "../../../components/seo/seo"
import Hero from "../../../components/hero/hero"
import HaveQuestions from "../../../components/haveQuestions/haveQuestions"
import BrandLogo from "../../../images/logos/elitek-logo.svg"
import QuestionsIcon from "../../../images/icons/icon-questions-elitek.svg"
import HeroIcon from "../../../images/icons/icon-enroll-elitek-hero.svg"
import BackToTop from "../../../components/backToTop/BackToTop"
import PrescribingInfo from "../../../components/prescribingInfo/prescribingInfo"
import EnrollCard from "../../../components/enrollCard/enrollCard"

import Monitor from "../../../images/icons/elitek/elitek-monitor-icon.svg"
import Printer from "../../../images/icons/elitek/elitek-printer-icon.svg"

const pageContent = () => (
  <Layout pageid="page-enrollment" pageClass="mobile-height-hero">
    <SEO
      title="CareASSIST enrollment for ELITEK® (rasburicase) | HCP Site"
      keywords="CareASSIST, Sanofi, ELITEK® (rasburicase), HCP Site, Enrollment"
      description="Ready to help a patient enroll? Enroll online. See full Prescribing Info, including Boxed WARNING"
    />

    <Hero
      brandLogo={BrandLogo}
      headline="Enrollment"
      copy="Care Managers can help your office and patients with the enrollment process"
      heroImgClass="hero-icon"
      hasButton={false}
      hasLogo={true}
      hasIcon={true}
      heroIcon={HeroIcon}
      iconAlt="Enrollment Icon"
      brandAlt="Elitek logo"
      isExternalButton={false}
      btnLink="../../../pdfs/careassist-program-enrollment-form.pdf"
      btnText=""
      btnClass="red"
    />

    <section className="content-section mobile-p-t-35">
      <main>
        <div className="main-content m-b-80">

          <h2 className="m-b-60 m-x-30 sm-m-x-0 fs-26">
            2 ways for your patients to get started with CareASSIST 
          </h2>

          <EnrollCard 
            cardImage={Monitor} 
            alt="Online enrollment icon"
            cardClass="elitek vertical-center"
            header="Online"
            enrollLinkText="Enroll online"
            btnClass="internal-btn red"
            content={<><strong>Select</strong> the button below and complete all requested fields. This will provide an electronic consent option for patients who are not in  your office at the time of enrollment.</>}
            btnLink="https://sanoficareassisthcp.iassist.com/"
            linkId="hcp-elitek-enrollment-enroll-online"
          />

          <h2 className="text-center m-y-40">OR</h2>


          <EnrollCard 
            alt="Fax enrollment icon"
            cardImage={Printer} 
            cardClass="elitek download-card align-center"
            header="Fax"
            downloadLinkText="Enrollment form"
            spanshDownloadLinkText="Spanish enrollment form"
            btnClass="internal-btn red"
            content={<><strong>Download</strong> and print the form, then fax it to us at:</>}
            fax="1-855-411-9689"
            btnLink="../../../pdfs/careassist-program-enrollment-form.pdf"
            spanishBtnLink="../../../pdfs/careassist-spanish-enrollment-form.pdf"
            linkId="hcp-elitek-enrollment-enroll-form"
            secondLinkId="hcp-elitek-enrollment-spanish-enroll-form"
          />
        </div>
      </main>
      
      <PrescribingInfo linkId="hcp-elitek-pi" link="https://products.sanofi.us/elitek/elitek.html" />
      <HaveQuestions icon={QuestionsIcon} />
    </section>
    <div className="back-to-top-container"> 
      <BackToTop />
    </div>
  </Layout>
);

export default pageContent